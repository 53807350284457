<template>
  <div>
    <div class="headerContainer" v-show="loading" id="header" :class="{
        'headerContainer--home': homeState,
        'headerContainer--group': groupState,
      }">
      <div class="inner">
        <img class="logo" :src="logo" alt="" />
        <ul class="list" v-show="!showSearch">
          <li class="listItem" id="listItem" v-for="item in menus" :key="item.id" :class="{
              active: item.path != '/group' && item.path === navUrl,
              'listItem--home': homeState,
              'listItem--group': groupState,
            }" @click="jump(item)">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <!-- loading -->
    <div class="loading" v-if="!loading"></div>
  </div>
</template>

<script>
import router from '@/router'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import { getToken } from '@/utils'
export default {
  name: 'Header',
  props: {},
  directives: { Clickoutside },
  data() {
    return {
      showSearch: false,
      searchValue: '',
      logo: require('@/assets/logo.png'),
      topYOffset: 0,
      show: true,
      loading: false,
      homeState: false,
      groupState: false,
    }
  },
  mounted() {
    this.getPath()
  },
  methods: {
    // 获取路径
    getPath() {
      let path = window.location.href.split('#/')[1]
      console.log('path***1111', path)
      this.$store.commit('setNavUrl', `/${path}`)
      sessionStorage.setItem('NAVURL', `/${path}`)
      if (path == '') {
        this.logoUpdate('/')
      } else if (path == 'group') {
        this.logoUpdate('/group')
      }
    },
    async handleChange() {
      console.log(this.searchValue)
      router.replace(`/result/${this.searchValue}`)
      this.setHideSearch()
      console.log(this.$route.fullPath)
      if (this.$route.fullPath.split('/')[1] === 'result') {
        this.$router.go(0)
      }
    },
    logoUpdate(path) {
      if (path == '/') {
        this.homeState = true
        this.groupState = false
        this.logo = require('@/assets/home/logo.png')
      } else if (path.indexOf('/group') != -1) {
        this.homeState = true
        this.groupState = false
        this.logo = require('@/assets/home/logo.png')
        // this.homeState = false
        // this.groupState = true
        // this.logo = require('@/assets/group/logo.png')
      } else {
        this.homeState = false
        this.groupState = false
        this.logo = require('@/assets/logo.png')
      }
    },
    jump(item) {
      console.log('jump', item.path)
      const { path, disable } = item
      if (disable === 1) {
        return
      }
      const urlArr = path.split('/')
      console.log('urlArr', urlArr)
      this.$store.commit('setNavUrl', `/${urlArr[1]}`)
      this.$store.commit('setActiveItem', item)
      sessionStorage.setItem('NAVURL', `/${urlArr[1]}`)
      sessionStorage.setItem('ACTIVEITEM', JSON.stringify(item))
      router.push(path)
    },
    setShowSearch() {
      this.showSearch = true
    },
    setHideSearch() {
      this.showSearch = false
      this.searchValue = ''
    },
  },
  computed: {
    navUrl() {
      return this.$store.state.navUrl
    },
    menus() {
      return this.$store.state.menus || []
    },
    flatMenus() {
      let newMenus = []
      this.menus.forEach((item) => {
        newMenus.push(item)
        if (item.child) {
          newMenus.push(...item.child)
        }
      })
      return newMenus
    },
  },
  watch: {
    '$store.state.menus'() {
      this.getPath()
      this.loading = true
    },
    $route(to, from) {
      if (from.name) {
        if (to.fullPath !== '/login') {
          const { fullPath } = to
          const urlArr = fullPath.split('/')
          // const activeItem = this.flatMenus.find(
          //   (item) => item.path === fullPath
          // );
          // console.log(fullPath.indexOf("/infoDynamic/news?id="));
          if (fullPath.indexOf('/infoDynamic/news?id=') === -1) {
            this.logoUpdate(fullPath)
            // console.log(fullPath);
            const activeItem = this.flatMenus.find(
              (item) => item.path === fullPath
            )
            // console.log(activeItem, 123);
            this.$store.commit('setNavUrl', `/${urlArr[1]}`)
            this.$store.commit('setActiveItem', activeItem)
            sessionStorage.setItem('NAVURL', `/${urlArr[1]}`)
            if (sessionStorage.getItem('NAVURL') !== '/result') {
              sessionStorage.setItem('ACTIVEITEM', JSON.stringify(activeItem))
            }
            // console.log(fullPath);
            // console.log(sessionStorage.getItem("ACTIVEITEM"));
            // console.log(sessionStorage.getItem("NAVURL"));
            // router.push(fullPath);
            // console.log(urlArr);
            // console.log(urlArr[1]);
          }

          if (urlArr[1] === 'hySchool') {
            if (getToken() === '') {
              router.replace('/login')
              // console.log("去登陆页");
              // router.push({ path: "/login" });
            } else {
              router.push(fullPath)
            }
          } else {
            router.push(fullPath)
          }
        } else {
          router.push('/login')
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.headerContainer--home {
  background-color: rgba(0, 0, 0, 0.25) !important;
  color: #fff;
  .listItem--home {
    color: #fff;
  }
}
.headerContainer--group {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #fff;
  box-shadow: none !important;
  .listItem--group {
    color: #fff;
  }
  .listItem--group:nth-child(3) {
    border-bottom: 2px solid #fff !important;
  }
}
.headerContainer {
  z-index: 100;
  box-shadow: 17px -7px 18px #ccc;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  padding: 0 1rem;
  height: 0.92rem;
}
.inner {
  max-width: 1440px;
  height: 0.92rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 0.4rem;
  width: 8.34rem;
  height: 0.45rem;
  background-color: #f5f5f5;
  border-radius: 0.02rem;
  input {
    background-color: #f5f5f5;
    width: 100%;
    color: rgb(66, 66, 66);
    font-size: 0.2rem;
    outline: none;
    border: none;
  }
  img {
    height: 0.2rem;
    width: auto;
  }
}
.logo {
  width: auto;
  height: 0.5rem;
  align-self: center;
}
.search {
  cursor: pointer;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  img {
    height: 0.22rem;
    width: auto;
  }
}
.list {
  display: flex;
}
.listItem {
  height: 0.92rem;
  line-height: 0.92rem;
  position: relative;
  margin-right: 0.5rem;
  color: rgb(66, 66, 66);
  font-size: 0.2rem;
  font-weight: 400;
  cursor: pointer;
  .childrenList {
    display: none;
    box-sizing: border-box;
    position: absolute;
    top: 0.92rem;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #fff;
    .childrenListItem {
      text-align: center;
      padding: 0.11rem 0.14rem;
      font-size: 0.18rem;
      line-height: 0.18rem;
      font-weight: 400;
      color: rgb(66, 66, 66);
      &:last-child {
        border-color: transparent;
      }
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    .childrenList {
      display: block;
    }
  }
}
.active {
  position: relative;
  color: rgb(66, 66, 66);
  font-weight: 600;
  border-bottom: 2px solid #181e2f;
}
.active--white {
  position: relative;
  font-weight: 600;
  border-bottom: 2px solid #fff !important;
}
.submit {
  z-index: 5;
  cursor: pointer;
}
.loading {
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: relative;
  z-index: 999;
}
</style>
